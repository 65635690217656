'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.users.controller:UserManagementCtrl

 # @description

###
class UserManagementCtrl
  ### @ngInject ###
  constructor:(
    $state
  ) ->
    @currentTab = $state.current.data.selectedTab

angular
  .module('mundoAdmin.users')
  .controller 'UserManagementCtrl', UserManagementCtrl
